import React from "react";
import PageTransition from "gatsby-plugin-page-transitions";
import {
  Jumbotron,
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Image
} from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import Section from "../components/Section/Section";
import SEO from "../components/Seo/Seo";
import { P, H1, H2, Span } from "../components/Typography/Typography";
import logoLuxoft from "../images/sf-partner-luxoft.jpg";
import logoSoftbinator from "../images/sf-partner-softbinator.jpg";
import logoCegeka from "../images/sf-partner-cegeka.jpg";
import raduAmarie from "../images/radu-amarie.jpg";
import danielIlinca from "../images/daniel-ilinca.jpg";
import gabrielaAmarie from "../images/gabriela-amarie.jpg";
import dorinMinea from "../images/dorin-minea.jpg";
import violetaGaina from "../images/violeta-gaina.jpg";
import raducuRoman from "../images/raducu-roman.jpg";
import ralucaPoiana from "../images/raluca-poiana.jpg";
import mihaiMaruseac from "../images/mihai-maruseac.jpg";
import caterinaApostol from "../images/caterina-apostol.jpg";

const AboutPage = () => (
  <PageTransition>
    <Layout>
      <SEO title="About" />
      <Container>
        <Jumbotron as="header" className="sf-about d-flex align-items-center">
          <Row>
            <Col lg={8}>
              <H1>About us</H1>
              <P>
                Softbinator Foundation is a non-profit organization that through
                interaction, interest and passion raises performance standards
                throughout Romania’s IT community.
              </P>
            </Col>
          </Row>
        </Jumbotron>
      </Container>

      <Section className="sf-who-we-are">
        <Container>
          <Row>
            <Col>
              <H2>Who we are</H2>
              <P>
                Since 2012, we organize events to help programmers develop tech
                skills, share opinions, showcase what they are working on, and
                check out the epic things others are building.
              </P>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col lg={6}>
              <Card className="our-mission px-3 py-3">
                <Card.Body>
                  <Card.Title>Our Mission</Card.Title>
                  <Card.Text>
                    Our mission is to create the largest IT community in Romania
                    and contribute to the growth of excellence through
                    professional development events, networking and support for
                    social projects.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6}>
              <Card className="our-vision px-3 py-3">
                <Card.Body>
                  <Card.Title>Our Vision</Card.Title>
                  <Card.Text>
                    Our vision is a big and strong IT community that will help
                    Social Impact Startups from the tech industry to make a
                    better world.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="sf-our-story">
        <Container>
          <Row>
            <Col>
              <H2>Our story</H2>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col>
              <P>
                In 2011, Radu Amarie and Daniel Ilinca already had experience
                working as developers in different companies and found out that
                a lot of developers are not happy with the work they did and
                wanted to try or learn something more challenging, but they
                didn’t know exactly where to start. <br />
                <br />
                The initial plan was to build a software platform for better
                matching developers expectations with companies needs and did
                it. For launching the brand new platform, Radu and Daniel did a
                big conference in 2012 (around 500 participants) that almost
                bankrupted them...
                <br />
                <br />
                But magic happened, and that event was a success. There were two
                days of presentations, workshops and networking sessions where
                people were sharing from their experience and learning from each
                other. It was great watching them smiling and talking and they
                said:
                <br /> "This is what we need to do! Events for developers, they
                need to meet, get away from their computers and talk".
                <br />
                <br />
                In 2013, Gabriela Bejan (Ms Amarie in our days) joined Radu and
                Daniel and started to focus on building the largest community
                for software developers in Bucharest. This is when “Talks by
                Softbinator” started and they realized no matter what,
                Softbinator will represent an important chapter in their life.
              </P>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="sf-our-days">
        <Container>
          <Row className="mt-3 mb-3 justify-content-center">
            <Col lg={8}>
              <P className="text-center">
                In our days we have Softbinator Foundation as it is known by the
                tech community, a non-profit organisation to carry on what
                Gabriela, Radu and Daniel started. Creating a strong and engaged
                tech community around Talks events in all the country. The
                ambition of the foundation is that together with the community
                one day they can help Social Impact Startups from the tech
                industry to make a better world.
              </P>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="sf-team">
        <Container>
          <Row>
            <Col>
              <H2>Team</H2>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col>
              <ListGroup className="team flex-wrap" horizontal>
                <ListGroup.Item className="col-6 col-sm-4 col-md-2">
                  <Image
                    src={raduAmarie}
                    className="img-fluid"
                    alt="Radu Amarie"
                  />
                  <P>
                    Radu Amarie <Span>Co-President</Span>
                  </P>
                </ListGroup.Item>
                <ListGroup.Item className="col-6 col-sm-4 col-md-2">
                  <Image
                    src={danielIlinca}
                    className="img-fluid"
                    alt="Daniel Ilinca"
                  />
                  <P>
                    Daniel Ilinca <Span>Co-President</Span>
                  </P>
                </ListGroup.Item>
                <ListGroup.Item className="col-6 col-sm-4 col-md-2">
                  <Image
                    src={gabrielaAmarie}
                    className="img-fluid"
                    alt="Gabriela Amarie"
                  />
                  <P>
                    Gabriela Amarie <Span>Co-Founder</Span>
                  </P>
                </ListGroup.Item>
                <ListGroup.Item className="col-6 col-sm-4 col-md-2">
                  <Image
                    src={dorinMinea}
                    className="img-fluid"
                    alt="Dorin Minea"
                  />
                  <P>
                    Dorin Minea <Span>Executive Director</Span>
                  </P>
                </ListGroup.Item>
                <ListGroup.Item className="col-6 col-sm-4 col-md-2">
                  <Image
                    src={violetaGaina}
                    className="img-fluid"
                    alt="Violeta Gaina"
                  />
                  <P>
                    Violeta Gaină <Span>Project Manager Bucharest</Span>
                  </P>
                </ListGroup.Item>
                <ListGroup.Item className="col-6 col-sm-4 col-md-2">
                  <Image
                    src={raducuRoman}
                    className="img-fluid"
                    alt="Raducu Roman"
                  />
                  <P>
                    Răducu Roman <Span>Project Manager Constanța</Span>
                  </P>
                </ListGroup.Item>
                <ListGroup.Item className="col-6 col-sm-4 col-md-2">
                  <Image
                    src={ralucaPoiana}
                    className="img-fluid"
                    alt="Raluca Poiana"
                  />
                  <P>
                    Raluca Poiană <Span>Grafic Designer</Span>
                  </P>
                </ListGroup.Item>
                <ListGroup.Item className="col-6 col-sm-4 col-md-2">
                  <Image
                    src={mihaiMaruseac}
                    className="img-fluid"
                    alt="Mihai Maruseac"
                  />
                  <P>
                    Mihai Maruseac <Span>Ambasador</Span>
                  </P>
                </ListGroup.Item>
                <ListGroup.Item className="col-6 col-sm-4 col-md-2">
                  <Image
                    src={caterinaApostol}
                    className="img-fluid"
                    alt="Caterina Apostol"
                  />
                  <P>
                    Caterina Apostol <Span>Social Media Manager</Span>
                  </P>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="sf-our-partners">
        <Container>
          <Row>
            <Col>
              <H2>Our partners</H2>
            </Col>
          </Row>
          <Row className="mt-5 mb-3">
            <Col lg={9}>
              <ListGroup className="partners" horizontal>
                <ListGroup.Item className="col-sm-4">
                  <a
                    href="https://www.luxoft.com/"
                    title="Luxoft"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image
                      src={logoLuxoft}
                      className="img-fluid"
                      alt="Luxoft"
                    />
                  </a>
                </ListGroup.Item>
                <ListGroup.Item className="col-sm-4">
                  <a
                    href="https://www.softbinator.com/"
                    title="Softbinator"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image
                      src={logoSoftbinator}
                      className="img-fluid"
                      alt="Softbinator Technologies"
                    />
                  </a>
                </ListGroup.Item>
                <ListGroup.Item className="col-sm-4">
                  <a
                    href="https://www.cegeka.com/en/ro/"
                    title="cegeka"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image
                      src={logoCegeka}
                      className="img-fluid"
                      alt="cegeka"
                    />
                  </a>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  </PageTransition>
);

export default AboutPage;
